@import "variables";

.badge {
  padding: .45em 1.4em;
}

.badge-success {
  background-color: transparentize($success, 0.8);
  color: darken($success, 0%);
}

.badge-warning {
  background-color: transparentize($warning, 0.8);
  color: darken($warning, 0%);
}

.badge-danger {
  background-color: transparentize($danger, 0.8);
  color: darken($danger, 0%);
}

.badge-darker-red{
  $badge-color: #ac0081;
  background-color: transparentize($badge-color, 0.8);
  color: darken($badge-color, 0%);
}

.badge-info {
  background-color: transparentize($info, 0.8);
  color: darken($info, 0%);
}

.badge-primary {
  background-color: transparentize($primary, 0.8);
  color: darken($primary, 0%);
}

.badge-secondary {
  background-color: transparentize($secondary, 0.8);
  color: darken($secondary, 0%);
}

.badge-purple {
  background-color: transparentize($color-purple, 0.8);
  color: darken($color-purple, 0%);
}

.badge-gray{
  background-color: transparentize($color-dark-gray, 0.8);
  color: darken($color-dark-gray, 0%);
}
