@import "variables";

.sidebar {
  .sidebar-nav {
    .nav {
      .nav-title {
      }

      .nav-item {
        transition: none;

        &:active {
          outline: none;
        }

        .nav-link {
          color: $color-dark-blue;
          outline: none;
          font: 14px Montserrat;
          margin-left: -7px;

          &:hover {
            color: $color-blue;

            .nav-icon {
              color: $color-blue;
            }
          }

          &.active {
            background: $color-light-blue;
            color: $color-blue;

            .nav-icon {
              color: $color-blue;
            }
          }

          .nav-icon {
            color: $color-dark-gray;
          }
        }
      }

      .nav-item.nav-dropdown {
        &.open {
          background-color: $color-light-gray;

          .nav-dropdown-toggle {
            background-color: darken($color-light-gray, 5%);
          }

          .nav-dropdown-items {
            .nav-item {
              .nav-link {
                padding-left: 26px;
                font: 11px Montserrat;

                &.active {
                  border-left: 0;
                  background-color: transparent;
                }
              }
            }
          }

          &.active {
            .nav-dropdown-toggle {
              color: $color-blue;
              border-left: $sidebar-active-border-length solid $color-blue;
              padding-left: 11px;
              background-color: darken($color-light-gray, 5%);

              .nav-icon {
                color: $color-blue;
              }
            }
          }

          .nav-link.nav-dropdown-toggle {
            cursor: pointer;
            user-select: none;
            -moz-user-select: none;
            -webkit-user-select: none;
            -ms-user-select: none;
          }
        }
      }
    }
  }

  .sidebar-minimized .sidebar .nav > .nav-dropdown:hover > .nav-dropdown-items {
    left: $navbar-minimized-length !important;
  }

  .sidebar-minimized .sidebar .sidebar-nav {
    .nav-item {
      &:hover {
        .nav-link {
          border-color: $color-gray;

          &.active {
            border-color: darken($color-gray, 5%);
          }
        }
      }

      .nav-link {
        border: 1px solid transparent;
        padding-left: $sidebar-active-border-length;
        border-left: 0;

        &.active {
          padding-left: 0 !important;
        }

        .nav-icon {
          margin-left: -5px;
        }
      }

      &.nav-dropdown {
        &.open {
          background-color: transparent !important;
          border-right: 0;
        }

        &.active {
          .nav-dropdown-toggle {
            padding-left: 0 !important;
          }
        }

        .nav-dropdown-items {
          .nav-item {
            .nav-link {
              padding: 0.6rem 1rem;
              padding-left: 11px !important;
              font-size: .8rem;
              border: 0;

              &:hover {
                border-right: 1px solid $color-gray !important;
              }

              &.active {
                border-right: 1px solid $color-gray;
                border-left: 0;

                &:hover {
                  background-color: $color-light-gray !important;
                }
              }

              .nav-icon {
                font-size: .8rem;
              }
            }
          }

          .nav-item:last-child {
            border-bottom: 1px solid $color-gray;
          }
        }
      }
    }
  }
}

.sidebar-minimized .sidebar .nav-item:hover {
  background-color: white;
}
