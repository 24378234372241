// Variable overrides

// Variables iSupplier v2 [COLORS]
// primary colors
$color-primary-1: #E74C3C;
$color-primary-2: #3498DB;
$color-primary-3: #3498DB0D;

// secondary colors
$color-secondary-1: #F53D6B;
$color-secondary-2: #F1C40F;
$color-secondary-3: #9B59B6;
$color-secondary-4: #2ECC71;
// base colors
$color-base-1: #2C3E50;
$color-base-2: #34495E;
$color-base-3: #4d6884;
// gray scale colors
$color-gray-1: #95A5A6;
$color-gray-2: #E4E4E4;
$color-gray-3: #f7f7f9;

$color-white: #FFFFFF;

$color-red-1: #ec887e;

// Bootstrap variables [COLORS]
$primary: $color-primary-1;
$secondary: $color-gray-2;
$info: $color-primary-2;
$danger: $color-secondary-1;
$warning: $color-secondary-2;
$success: $color-secondary-4;
$light: $color-gray-3;
$dark: $color-base-1;

// Fonts
$font-title: 'Montserrat', Arial, sans-serif;
$font-text: 'Helvetica', Arial, sans-serif;

// Variables isupplier
$color-isupplier: $primary;
$color-blue: $info;
$color-dark: $color-base-1;
$color-dark-blue: $color-base-2;
$color-title: $color-base-1;
$color-text: $color-base-2;
$color-dark-gray: $color-gray-1;
$color-gray: $color-gray-2;
$color-light-gray: $color-gray-3;
$color-light-red: $color-red-1;
$color-purple: $color-secondary-3;
$color-border: $color-gray-2;
$font-title: 'Montserrat', Arial, sans-serif;
$font-text: 'Helvetica', Arial, sans-serif;
$color-light-blue: $color-primary-3;

//TEXTES COULEURS
/*$primary: $color-isupplier;
$secondary: $color-gray;
$info: $color-blue;
$danger: rgb(246, 79, 37);
$light: $color-light-gray;
$dark: $color-dark;
$font-title: 'Montserrat', Arial, sans-serif;
$font-text: "Helvetica", Arial, sans-serif;*/


//STRUCTURES
// Body
$body-bg: $color-light-gray;
$body-color: $color-text;

//Navbar
$navbar-minimized-length: 50px;
$navbar-bg: #fff;
$navbar-brand-bg: transparent;
$navbar-color: $color-text;
$navbar-hover-color: $color-isupplier;
$navbar-active-color: $color-isupplier;
$navbar-disabled-color: $color-gray;

//SideBar
$sidebar-color: $color-text;
$sidebar-bg: #fff;
$sidebar-active-border-length: 5px;

$sidebar-nav-title-color: $color-text;
$sidebar-nav-link-color: $color-text;
$sidebar-nav-link-icon-color: $color-text;
$sidebar-nav-link-hover-color: $color-isupplier;
$sidebar-nav-link-hover-bg: $color-light-gray;
$sidebar-nav-link-hover-icon-color: $color-isupplier;
$sidebar-nav-link-active-color: $color-isupplier;
$sidebar-nav-link-active-icon-color: $color-isupplier;
$sidebar-nav-link-active-bg: $color-light-gray;
$sidebar-nav-link-disabled-color: $color-text;

//Navs
$nav-pills-link-active-color: $color-blue;
$nav-pills-link-active-bg: transparent;

//Fil d'ariane breadcrumb
$breadcrumb-bg: transparent;
$breadcrumb-divider: quote("|");

//Aside droit
$aside-menu-color: $color-text;
$aside-menu-bg: #fff;

//Cards
$card-cap-color: #fff;
$card-cap-bg: $color-dark;
$card-bg: #fff;
$card-border-color: $color-border;
$card-border-width: 0;

// Tooltips
$popover-header-bg: $color-light-gray;

//Badge
$badge-padding-x: .8em;
$badge-border-radius: 20rem;

//Dropdown
$dropdown-divider-bg: #fff;
$dropdown-border-color: $color-border;
$dropdown-link-color: $color-text;
$dropdown-link-hover-color: $color-blue;
$dropdown-link-hover-bg: $color-light-gray;
$dropdown-link-active-color: $color-isupplier;
$dropdown-link-active-bg: $color-light-gray;
$dropdown-link-disabled-color: $color-gray;

//GENERAL
//Liens
$link-color: $color-blue;

//Contours
$border-color: $color-border;

//Buttons
$btn-link-disabled-color: $color-light-gray;
$btn-border-radius: 25rem;
$btn-border-radius-lg: 25rem;
$btn-border-radius-sm: 25rem;
$btn-border-width: 1px;

//Tables
$table-accent-bg: $color-light-gray;
$table-head-bg: $color-dark;
$table-border-color: $color-border;
$table-head-color: #fff;
$table-hover-bg: $color-light-gray;

// Forms
$input-group-addon-bg: $color-border;
$input-border-color: $color-border;
$input-group-addon-border-color: $color-border;

//Input
$input-disabled-bg: $color-light-gray;
$input-color: $color-text;
$input-border-color: $color-border;

//Progress Bar
$progress-bg: $color-gray;
$progress-bar-bg: $color-isupplier;

//Pagination
$pagination-color: $color-text;
$pagination-border-color: none;
$pagination-hover-color: $color-text;
$pagination-active-bg: $color-isupplier;

//Jumbotron
$jumbotron-bg: $color-light-gray;

// List group
$list-group-disabled-bg: $color-gray;
