@import "variables";

.panel {
  padding: 1rem;
  margin-bottom: 1rem;

  &.panel-gray {
    background-color: transparentize($color-dark-gray, 0.8);
    color: darken($color-dark-gray, 0%);
  }
}
