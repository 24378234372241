@import "variables";

@keyframes animate-icon-circle {
  from {
    opacity: 0;
    transform: scale(1, 0);
  }
  to {
    opacity: 1;
    transform: scale(1, 1);
  }
}

@keyframes animate-icon-content {
  from {
    opacity: 0;
    transform: translateY(10px) scale(.7);
  }
  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

.icon-warning {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 7em;
  height: 7em;
  margin: auto auto 2rem;
  border: .35em solid $warning;
  color: $warning;
  border-radius: 50%;
  line-height: 7em;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  animation: animate-icon-circle .2s ease-in .1s;

  .icon-warning-content {
    display: flex;
    align-items: center;
    font-size: 4.5em;

    animation: animate-icon-content .3s cubic-bezier(.63,0,.39,1.78) .2s;
  }
}
