.btn-outline-blue {
  cursor: pointer;
  border: 2px solid #3498db;
  border-radius: 4px;
  color: #3498db;
}

.btn-outline-blue:hover {
  border-color: #3498db;
  background-color: #3498db;
  color: white;
}

.button-blue {
  background: #3498db;
  border: 2px solid #3498db;
  border-radius: 4px;
  text-align: center;
  letter-spacing: 0;
  color: white;
}

.button-blue:hover {
  background: transparent;
  color: #3498db;
  border: 2px solid #3498db;
}
