@import "variables";

.form-control {
  border-color: $color-border;
  background-color: $color-light-gray;
  color: $color-text;
}

.form-control:disabled {
  color: $color-dark-gray;
  border: none;
}

.form-control:focus {
  box-shadow: none;
  border-color: $color-dark-blue;
  background-color: transparentize(#FFF, 0.3);
}

.form-control-lg {
  padding: 0 1rem;
  font-size: .95rem;
}

.btn {
  border-radius: .25rem;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  box-shadow: none !important;
  border-color: $color-blue !important;
  background-color: $color-blue !important;
}

.custom-control-input ~ .custom-control-label::before {
  color: #fff;
  box-shadow: none !important;
  border-color: $color-border !important;
}

.custom-select {
  background-color: $color-light-gray;

  &:disabled {
    background-color: $color-light-gray;
    border: none;
  }
}

.btn-link {
  color: $color-isupplier;
  cursor: pointer;

  &:hover {
    color: darken($color-isupplier, 10%);
  }

  &:active {
    color: darken($color-isupplier, 15%);
  }
}

.form-group {
  label {
    color: $color-dark-gray;
  }
}

.adv-search-date-icon {
  position: relative;
  right: 10%;
  top: 5px;
  cursor: pointer;
  z-index: 1;
  line-height: 1;
  font-size: 18px;
  color: $color-gray-1;
}

.adv-search-date-icon:hover {
  color: $primary;
}
