@import "variables";

.switch {
  position: relative;
  display: block;
  width: 4rem;
  height: 1.5rem;
  cursor: pointer;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 34px;
  background-color: $color-dark-gray;
  -webkit-transition: .2s ease;
  transition: .2s ease;
}

.slider::before {
  position: absolute;
  content: "";
  height: 1.1rem;
  width: 1.1rem;
  border-radius: 50%;
  left: 4px;
  bottom: calc((1.5rem - 1.1rem) / 2);
  top: calc((1.5rem - 1.1rem) / 2);
  background-color: white;
  -webkit-transition: .2s ease;
  transition: .2s ease;
}

.label {
  display: block;
  position: relative;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.label::before {
  content: '';
  opacity: 0;
  position: absolute;
  top: 2px;
  left: 14px;
  z-index: 5;
  color: white;
  line-height: 18px;
  font-size: 13px;
  transition: .1s ease;
}

.label::after {
  content: '';
  opacity: 1;
  position: absolute;
  top: 2px;
  right: 15px;
  z-index: 5;
  color: white;
  line-height: 18px;
  font-size: 14px;
  transition: .1s ease;
}

.label-yes-no::before {
  content: 'YES';
}

.label-yes-no::after {
  content: 'NO';
}

.switch {
  input:checked + .slider::before {
    -webkit-transform: translateX(calc(4rem - 1.2rem - 8px));
    -ms-transform: translateX(calc(4rem - 1.2rem - 8px));
    transform: translateX(calc(4rem - 1.2rem - 8px));
  }

  input:checked ~ .label::before {
    opacity: 1;
  }

  input:checked ~ .label::after {
    opacity: 0;
  }
}

.switch-info {
  input:checked + .slider {
    background-color: $info;
  }
}

.switch-success {
  input:checked + .slider {
    background-color: $success;
  }
}

.switch-danger {
  input:checked + .slider {
    background-color: $danger;
  }
}

.switch-warning {
  input:checked + .slider {
    background-color: $warning;
  }
}
