.sidebar .sidebar-nav {
  width: 199px;
}

.sidebar .nav-item {
  padding-left: 4px;
  border-left: 4px;
}

.sidebar .nav-item.open {
  background: tomato;
}

.sidebar-minimized .sidebar .nav-item.open {
  width: 49px;
}

.navbar-brand {
  width: 200px !important;
}

.navbar-brand-mini {
  width: 50px !important;
}

navbar-brand-minimized {
  width: 50px !important;
}

img.navbar-brand-full {
  width: 140px !important;
  height: 65px !important;
}
