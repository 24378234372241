@-webkit-keyframes swal2-animate-success-line-tip {
  0% {
    top: 1.1875em;
    left: .0625em;
    width: 0
  }
  54% {
    top: 1.0625em;
    left: .125em;
    width: 0
  }
  70% {
    top: 2.1875em;
    left: -.375em;
    width: 3.125em
  }
  84% {
    top: 3em;
    left: 1.3125em;
    width: 1.0625em
  }
  100% {
    top: 2.8125em;
    left: .875em;
    width: 1.5625em
  }
}

@keyframes swal2-animate-success-line-tip {
  0% {
    top: 1.1875em;
    left: .0625em;
    width: 0
  }
  54% {
    top: 1.0625em;
    left: .125em;
    width: 0
  }
  70% {
    top: 2.1875em;
    left: -.375em;
    width: 3.125em
  }
  84% {
    top: 3em;
    left: 1.3125em;
    width: 1.0625em
  }
  100% {
    top: 2.8125em;
    left: .875em;
    width: 1.5625em
  }
}

@-webkit-keyframes swal2-animate-success-line-long {
  0% {
    top: 3.375em;
    right: 2.875em;
    width: 0
  }
  65% {
    top: 3.375em;
    right: 2.875em;
    width: 0
  }
  84% {
    top: 2.1875em;
    right: 0;
    width: 3.4375em
  }
  100% {
    top: 2.375em;
    right: .5em;
    width: 2.9375em
  }
}

@keyframes swal2-animate-success-line-long {
  0% {
    top: 3.375em;
    right: 2.875em;
    width: 0
  }
  65% {
    top: 3.375em;
    right: 2.875em;
    width: 0
  }
  84% {
    top: 2.1875em;
    right: 0;
    width: 3.4375em
  }
  100% {
    top: 2.375em;
    right: .5em;
    width: 2.9375em
  }
}

@-webkit-keyframes swal2-rotate-success-circular-line {
  0% {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg)
  }
  5% {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg)
  }
  12% {
    -webkit-transform: rotate(-405deg);
    transform: rotate(-405deg)
  }
  100% {
    -webkit-transform: rotate(-405deg);
    transform: rotate(-405deg)
  }
}

@keyframes swal2-rotate-success-circular-line {
  0% {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg)
  }
  5% {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg)
  }
  12% {
    -webkit-transform: rotate(-405deg);
    transform: rotate(-405deg)
  }
  100% {
    -webkit-transform: rotate(-405deg);
    transform: rotate(-405deg)
  }
}

@-webkit-keyframes swal2-animate-error-x-mark {
  0% {
    margin-top: 1.625em;
    -webkit-transform: scale(.4);
    transform: scale(.4);
    opacity: 0
  }
  50% {
    margin-top: 1.625em;
    -webkit-transform: scale(.4);
    transform: scale(.4);
    opacity: 0
  }
  80% {
    margin-top: -.375em;
    -webkit-transform: scale(1.15);
    transform: scale(1.15)
  }
  100% {
    margin-top: 0;
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1
  }
}

@keyframes swal2-animate-error-x-mark {
  0% {
    margin-top: 1.625em;
    -webkit-transform: scale(.4);
    transform: scale(.4);
    opacity: 0
  }
  50% {
    margin-top: 1.625em;
    -webkit-transform: scale(.4);
    transform: scale(.4);
    opacity: 0
  }
  80% {
    margin-top: -.375em;
    -webkit-transform: scale(1.15);
    transform: scale(1.15)
  }
  100% {
    margin-top: 0;
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1
  }
}

@-webkit-keyframes swal2-animate-error-icon {
  0% {
    -webkit-transform: rotateX(100deg);
    transform: rotateX(100deg);
    opacity: 0
  }
  100% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
    opacity: 1
  }
}

@keyframes swal2-animate-error-icon {
  0% {
    -webkit-transform: rotateX(100deg);
    transform: rotateX(100deg);
    opacity: 0
  }
  100% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
    opacity: 1
  }
}

.swal2-icon {
  position: relative;
  justify-content: center;
  width: 5em;
  height: 5em;
  margin: 1.25em auto 1.875em;
  border: .25em solid transparent;
  border-radius: 50%;
  line-height: 5em;
  cursor: default;
  box-sizing: content-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  zoom: normal
}

.swal2-icon.swal2-error {
  border-color: #f27474
}

.swal2-icon.swal2-error .swal2-x-mark {
  position: relative;
  flex-grow: 1
}

.swal2-icon.swal2-error [class^=swal2-x-mark-line] {
  display: block;
  position: absolute;
  top: 2.3125em;
  width: 2.9375em;
  height: .3125em;
  border-radius: .125em;
  background-color: #f27474
}

.swal2-icon.swal2-error [class^=swal2-x-mark-line][class$=left] {
  left: 1.0625em;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg)
}

.swal2-icon.swal2-error [class^=swal2-x-mark-line][class$=right] {
  right: 1em;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg)
}

.swal2-icon.swal2-warning {
  border-color: #facea8;
  color: #f8bb86
}

.swal2-icon.swal2-info {
  border-color: #9de0f6;
  color: #3fc3ee
}

.swal2-icon.swal2-question {
  border-color: #c9dae1;
  color: #87adbd
}

.swal2-icon.swal2-success {
  border-color: #a5dc86
}

.swal2-icon.swal2-success [class^=swal2-success-circular-line] {
  position: absolute;
  width: 3.75em;
  height: 7.5em;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  border-radius: 50%
}

.swal2-icon.swal2-success [class^=swal2-success-circular-line][class$=left] {
  top: -.4375em;
  left: -2.0635em;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 3.75em 3.75em;
  transform-origin: 3.75em 3.75em;
  border-radius: 7.5em 0 0 7.5em
}

.swal2-icon.swal2-success [class^=swal2-success-circular-line][class$=right] {
  top: -.6875em;
  left: 1.875em;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 0 3.75em;
  transform-origin: 0 3.75em;
  border-radius: 0 7.5em 7.5em 0
}

.swal2-icon.swal2-success .swal2-success-ring {
  position: absolute;
  top: -.25em;
  left: -.25em;
  width: 100%;
  height: 100%;
  border: .25em solid rgba(165, 220, 134, .3);
  border-radius: 50%;
  z-index: 2;
  box-sizing: content-box
}

.swal2-icon.swal2-success .swal2-success-fix {
  position: absolute;
  top: .5em;
  left: 1.625em;
  width: .4375em;
  height: 5.625em;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  z-index: 1
}

.swal2-icon.swal2-success [class^=swal2-success-line] {
  display: block;
  position: absolute;
  height: .3125em;
  border-radius: .125em;
  background-color: #a5dc86;
  z-index: 2
}

.swal2-icon.swal2-success [class^=swal2-success-line][class$=tip] {
  top: 2.875em;
  left: .875em;
  width: 1.5625em;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg)
}

.swal2-icon.swal2-success [class^=swal2-success-line][class$=long] {
  top: 2.375em;
  right: .5em;
  width: 2.9375em;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg)
}

[class^=swal2] {
  -webkit-tap-highlight-color: transparent
}

.swal2-animate-success-icon .swal2-success-line-tip {
  -webkit-animation: swal2-animate-success-line-tip .75s;
  animation: swal2-animate-success-line-tip .75s
}

.swal2-animate-success-icon .swal2-success-line-long {
  -webkit-animation: swal2-animate-success-line-long .75s;
  animation: swal2-animate-success-line-long .75s
}

.swal2-animate-success-icon .swal2-success-circular-line-right {
  -webkit-animation: swal2-rotate-success-circular-line 4.25s ease-in;
  animation: swal2-rotate-success-circular-line 4.25s ease-in
}

.swal2-animate-error-icon {
  -webkit-animation: swal2-animate-error-icon .5s;
  animation: swal2-animate-error-icon .5s
}

.swal2-animate-error-icon .swal2-x-mark {
  -webkit-animation: swal2-animate-error-x-mark .5s;
  animation: swal2-animate-error-x-mark .5s
}
