/* You can add global styles to this file, and also import other style files */
// If you want to override variables do it here
@import "style/variables";
// Import custom css of major components //TODO: should be moved later on to its corresponding component if it's not general
@import "style/header";
// Import CoreUI UI Kit
@import "node_modules/@coreui/coreui/scss/coreui";
// If you want to add something do it here
@import "style/custom";
@import url("https://fonts.googleapis.com/css?family=Montserrat|Open+Sans&display=swap");

.iti {
  width: 100%;
}

[lang=FR] .label-yes-no::before {
  content: "OUI";
}

[lang=FR] .label-yes-no::after {
  content: "NON";
}
